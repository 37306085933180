import { createStore } from 'redux';

// Define your initial state (including current language)
const initialState = {
  currentLanguage: 'en',
  
};

// Reducer function to handle state updates
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_LANGUAGE':
      return { ...state, currentLanguage: action.payload };
    default:
      return state;
  }
};

// Create the Redux store
const store = createStore(reducer);

export default store;