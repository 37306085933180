import React, { Suspense, lazy } from 'react';
import { render } from 'react-dom';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter, Routes, Route, ScrollRestoration } from 'react-router-dom';
import './index.css'
import Fallback from './components/elements/Fallback';
import Profile from './views/content/dashboard/user/Profile';
import PostMenu from './views/content/dashboard/author/PostMenu';
import Posts from './views/content/dashboard/author/Posts';
import Drafts from './views/content/dashboard/author/Drafts';
// import DevLanding from './views/developer/DevLanding';
// import DevAuth from './views/developer/DevAuth';
// import DevMenuBar from './views/developer/components/DevMenuBar';
// import DevDocs from './views/developer/DevDocs';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './store';


const Landing = lazy(() => import('./layouts/Landing'));
const Errorpage = lazy(() => import('./layouts/Errorpage'));
const Auth = lazy(() => import('./layouts/Auth'));
const Signin = lazy(() => import('./views/auth/Signin'));
const Signup = lazy(() => import('./views/auth/Signup'));
const EnterEmail = lazy(() => import("./views/auth/EnterEmail"));
const ForgotPwd = lazy(() => import("./views/auth/ForgotPwd"));
const EmailLink = lazy(() => import("./views/auth/GenrateEmailVerLink"));
const Verify = lazy(() => import("./views/auth/Verify"));
const ServerError = lazy(() => import('./layouts/ServerError'));
const ExpiredLink = lazy(() => import('./layouts/ExpiredLink'));
const Dashboard = lazy(() => import('./views/content/Dashboard'));
const NewContent = lazy(() => import('./layouts/NewContent'));
const TestbedContent = lazy(() => import('./layouts/TestbedContent'));

const rootElement = document.getElementById("root");
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <CookiesProvider>
        <Suspense fallback={<Fallback />}>
          <BrowserRouter>
          <Routes>
            <Route exact path="/"  element={<Landing />} />
              <Route path="phis/*"   element={<NewContent />}>
                <Route path="user/*" element={<Profile />}>
                  
                </Route>
            </Route>
            <Route path="fedgenclouds/*"   element={<TestbedContent />} />
            <Route path="dash/*"   element={<Dashboard />} />
            <Route exact path="auth/*"  element={<Auth />}>
              <Route path="signin" element={<Signin />} />
              <Route path="signup" element={<Signup />} />
              <Route path="verify/:id"  exact element={<Verify />} />
              <Route path="enteremail"  element={<EnterEmail />} />
              <Route path="veremail"  element={<EmailLink />} />
              <Route path="forgotpwd/:id"  element={<ForgotPwd />} />
            </Route>
            
            <Route path="expired" element={<ExpiredLink />} />
            <Route path="servererror" element={<ServerError />} />
            <Route path="error" element={<Errorpage />} />
            <Route path="*" element={<Errorpage />} />
            {/* Developer Routes */}
            {/* <Route exact path='developer' element={<DevLanding />} /> */}
            
            
          </Routes>
          </BrowserRouter>
        </Suspense>
      </CookiesProvider>
    </Provider>
  </React.StrictMode>,
  rootElement
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

