import React, { useState } from "react";
import { useCookies } from "react-cookie";
import DocumentMeta from "react-document-meta";
import { BiCog } from "react-icons/bi";
import { BsToggles } from "react-icons/bs";
import { CgNotes, CgSandClock } from "react-icons/cg";
import { RiDeleteBin6Line, RiDraftLine } from "react-icons/ri";
import { useJwt } from "react-jwt";
import { Link, Navigate, Route, Routes, useLocation } from "react-router-dom";
import AuthorCardLarge from "../../../../components/elements/AuthorCardLarge";
import ProfileCard from "../../../../components/elements/ProfileCard";
import ProtectedRoute from "../../../../components/functions/ProtectedRoute";
import AuthorSubmitted from "../author/AuthorSubmitted";
import Drafts from "../author/Drafts";
import PostMenu from "../author/PostMenu";
import Posts from "../author/Posts";
import Withdrawn from "../author/Withdrawn";
import ProfileSettings from "./ProfileSettings";

const publicNavigation = [
    {"name": "Settings", "href": "/phis/user/settings", "icon": <BiCog />},
]
const privateNavigation = [
    {"name": "My Posts", "href": "/phis/user/myposts", "icon": <CgNotes />},
    {"name": "Drafts", "href": "/phis/user/drafts", "icon": <RiDraftLine /> },
    {"name": "Pending", "href": "/phis/user/submitted", "icon": <CgSandClock /> },
    {"name": "Deleted", "href": "/phis/user/deleted", "icon": <RiDeleteBin6Line /> },
    {"name": "Settings", "href": "/phis/user/settings", "icon": <BiCog />},
]
const adminNavigation = [
    {"name": "My Posts", "href": "/phis/user/posts", "icon": <CgNotes />},
    {"name": "Settings", "href": "/phis/user/settings", "icon": <BiCog />},
    {"name": "Dashboard", "href": "/dash", "icon": <BsToggles />},

]

const meta = {
    title: "Profile | PHIS",
    description: "User Profile",
    canonical: "https://phistest.fedgen.net/phis/user",
    meta: {
      charset: "utf-8",
      name: {
        keywords: "PHIS,public,health,capic-ace,Africa"
      }
    }
  };

export default function Profile(props){
    const [isActive, setActive] = useState();
    const [cookies] = useCookies([])
    const { isExpired } = useJwt(cookies['plt'])
    let location = useLocation();

    if (isExpired || !cookies['plt']){
        return <Navigate to={'/auth/signin'} state={{ from: location }} />
    }

    const onClickNav = (idx) =>{
        setActive(idx);
    }
    return (
        <>
        <div>
            <DocumentMeta {...meta} />
        </div>
        <div class="p-16">
            <ProfileCard id={props.data.unique_id} />
        </div>
        <div className="flex items-center -mx-4 space-x-2 overflow-x-auto overflow-y-hidden sm:justify-center flex-nowrap text-blue-900 ">
            {props.user_role === "A" && 
            privateNavigation.map((nav, idx) => (
                (idx !== isActive &&
                <Link onClick={() => {onClickNav(idx)}} to={nav.href} className="flex items-center flex-shrink-0 px-5 py-2 border-b-4 border-blue-200">
                    {nav.icon}
                    <span>{nav.name}</span>
                </Link>) ||
                (idx === isActive &&
                <Link onClick={() => {onClickNav(idx)}} to={nav.href} className="flex items-center flex-shrink-0 px-5 py-2 border-b-4 border-blue-900  ">
                    {nav.icon}
                    <span>{nav.name}</span>
                </Link> 
                    )
            ))}
            {props.user_role === "S" && 
            adminNavigation.map((nav, idx) => (
                (idx !== isActive &&
                <Link onClick={() => {onClickNav(idx)}} to={nav.href} className="flex items-center flex-shrink-0 px-5 py-2 border-b-4 border-blue-200">
                    {nav.icon}
                    <span>{nav.name}</span>
                </Link>) ||
                (idx === isActive &&
                <Link onClick={() => {onClickNav(idx)}} to={nav.href} className="flex items-center flex-shrink-0 px-5 py-2 border-b-4 border-blue-900 ">
                    {nav.icon}
                    <span>{nav.name}</span>
                </Link> 
                    )
            ))}
            {props.user_role === "R" && 
            adminNavigation.map((nav, idx) => (
                (idx !== isActive &&
                <Link onClick={() => {onClickNav(idx)}} to={nav.href} className="flex items-center flex-shrink-0 px-5 py-2 border-b-4 border-blue-200 ">
                    {nav.icon}
                    <span>{nav.name}</span>
                </Link>) ||
                (idx === isActive &&
                <Link onClick={() => {onClickNav(idx)}} to={nav.href} className="flex items-center flex-shrink-0 px-5 py-2 border-b-4 border-blue-900 ">
                    {nav.icon}
                    <span>{nav.name}</span>
                </Link> 
                    )
            ))}
            {props.user_role === "P" && 
            publicNavigation.map((nav, idx) => (
                (idx !== isActive &&
                <Link onClick={() => {onClickNav(idx)}} to={nav.href} className="flex items-center flex-shrink-0 px-5 py-2 border-b-4 border-blue-200" >
                    {nav.icon}
                    <span>{nav.name}</span>
                </Link>) ||
                (idx === isActive &&
                <Link onClick={() => {onClickNav(idx)}} to={nav.href} className="flex items-center flex-shrink-0 px-5 py-2 border-b-4 border-blue-900 ">
                    {nav.icon}
                    <span>{nav.name}</span>
                </Link> 
                    )
            ))}
        </div>
        <Routes>
            <Route exact path="/posts/*" element={
                <ProtectedRoute
                    isAllowed={!isExpired && (props.user_role !== undefined && props.user_role !== "P")}
                    ><PostMenu 
                        user={props.data} 
                        role={props.user_role} 
                        user_id={props.user_id}/></ProtectedRoute>
                } />
                
                
                <Route path="/settings" element={
                    <ProtectedRoute
                        isAllowed={!isExpired && props.user_role !== undefined }
                        ><ProfileSettings data={props.data} role={props.user_role} /></ProtectedRoute>
                } /> 
                
        </Routes>
        </>
    )
}