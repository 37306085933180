import React, { useState } from "react";
import { useCookies } from "react-cookie";
import DocumentMeta from "react-document-meta";
import { CgNotes, CgSandClock } from "react-icons/cg";
import { RiDeleteBin6Line, RiDraftLine } from "react-icons/ri";
import { useJwt } from "react-jwt";
import { Link, Route, Routes } from "react-router-dom";
import ProtectedRoute from "../../../../components/functions/ProtectedRoute";
import Withdrawn from "../author/Withdrawn";
import AuthorSubmitted from "./AuthorSubmitted";
import Drafts from "./Drafts";
import Posts from "./Posts";

const postNavigation = [
    {"name": "Approved", "href": "/phis/user/posts/approved", "icon": <CgNotes />, "isActive": true},
    {"name": "Drafts", "href": "/phis/user/posts/drafts", "icon": <RiDraftLine />, "isActive": false },
    {"name": "Pending", "href": "/phis/user/posts/submitted", "icon": <CgSandClock /> },
    {"name": "Deleted", "href": "/phis/user/posts/deleted", "icon": <RiDeleteBin6Line /> },
]

const meta = {
    title: "Profile | PHIS",
    description: "User Profile",
    canonical: "https://phistest.fedgen.net/phis/user",
    meta: {
      charset: "utf-8",
      name: {
        keywords: "PHIS,public,health,capic-ace,Africa"
      }
    }
  };

export default function PostMenu(props){
    const [isActive, setActive] = useState();
    const [cookies] = useCookies([])
    const { isExpired } = useJwt(cookies['plt'])

    const onClickNav = (idx) =>{
        setActive(idx);
    }
    return (
        <>
        <div>
            <DocumentMeta {...meta} />
        </div>
        <div className="flex items-center -mx-4 space-x-2 overflow-x-auto overflow-y-hidden sm:justify-center flex-nowrap text-blue-900 ">
      { 
          postNavigation.map((nav, idx) => (
              (idx !== isActive &&
              <Link onClick={() => {onClickNav(idx)}} to={nav.href} className="flex items-center flex-shrink-0 px-5 py-2 border-b-4 dark:border-gray-200 ">
                  {nav.icon}
                  <span>{nav.name}</span>
              </Link>) ||
              (idx === isActive &&
              <Link onClick={() => {onClickNav(idx)}} to={nav.href} className="flex items-center flex-shrink-0 px-5 py-2 border-b-4 dark:border-blue-900 ">
                  {nav.icon}
                  <span>{nav.name}</span>
              </Link> 
                  )
          ))}
        </div>
        <Routes>
            <Route exact path="/approved" element={
                <ProtectedRoute
                    isAllowed={!isExpired && (props.role !== undefined && props.role !== "P")}
                    ><Posts user={props.user} role={props.role} user_id={props.user_id}/></ProtectedRoute>
                } />
                <Route path="/drafts" element={
                    <ProtectedRoute
                        isAllowed={!isExpired && (props.role !== undefined && props.role !== "P")}
                        ><Drafts user={props.user} role={props.role} /></ProtectedRoute>
                }>
                </Route>
                <Route path="/submitted" element={
                    <ProtectedRoute
                        isAllowed={!isExpired && props.role !== undefined && props.role !== "P"}
                        ><AuthorSubmitted data={props.user} role={props.role} /></ProtectedRoute>
                } /> 
                <Route path="/deleted" element={
                    <ProtectedRoute
                        isAllowed={!isExpired && props.role !== undefined && props.role !== "P"}
                        ><Withdrawn user={props.user} role={props.role} /></ProtectedRoute>
                } />  
        </Routes>
        </>
    )
}