import React,{ Fragment, useState } from 'react'
import { Menu, Popover, Transition } from '@headlessui/react'
import {
  ChatAltIcon,
  CodeIcon,
  DotsVerticalIcon,
  EyeIcon,
  FlagIcon,
  PlusIcon,
  SearchIcon,
  ShareIcon,
  StarIcon,
  ThumbUpIcon,
} from '@heroicons/react/solid'
import { BellIcon, FireIcon, HomeIcon, MenuIcon, TrendingUpIcon, UserGroupIcon, XIcon } from '@heroicons/react/outline'
import { BiCog } from "react-icons/bi";
import { BsToggles } from "react-icons/bs";
import { CgNotes, CgSandClock } from "react-icons/cg";
import { RiDeleteBin6Line, RiDraftLine } from "react-icons/ri";
import {
  useQuery,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { Chip } from '@mui/material'
import { useCookies } from 'react-cookie'
import Loading from '../../../../components/elements/Loading'
import LoadingError from '../../../../components/elements/LoadingError'
import Errorpage from '../../../../layouts/Errorpage'
import Card from '../../../../components/elements/Card'
import { Link } from 'react-router-dom';

const queryClient = new QueryClient()




export default function Posts(props){
  return(
    <QueryClientProvider client={queryClient}>
      <App user={props.user} role={props.role} user_id={props.user_id}/>
    </QueryClientProvider>
  )
}

function App(props) {
  const [isActive, setActive] = useState();
  const [cookies] = useCookies([]);
  const [visible, setVisible] = useState(10)
  const { isLoading, error, data } = useQuery(['postData', props.user.unique_id], () => 
    fetch(process.env.REACT_APP_CONTENT_URL + '/content/posts-by-author/'+props.user.unique_id, {
        credentials: 'include',
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': cookies['plt']
        }
    }).then(res => 
      res.json()
      ),
      {
        retry: false,
        refetchOnWindowFocus: false
      }
    )
    if (isLoading) return <Loading />
    if (error) return <LoadingError />
    if (!(props.role == 'A'  || props.role == 'S' || props.role === 'R')) return <Errorpage />
    const onLoadMoreClick = () => {
      setVisible(v => v+6);
    }
    const onClickNav = (idx) =>{
        setActive(idx);
    }
  return (
    <>
      
        <div className="max-w-full w-full mx-auto sm:px-6 lg:px-8 space-y-3">
          <section className="text-gray-100">
            {data.posts.length === 0 && 
            <>
            <div className="text-gray-700 text-center">
              <p>No Posts yet. Write something!</p>
            </div>
            </>}

          {data.posts.length > 0 &&(
          <div className="container max-w-full w-full p-6 mx-auto space-y-2">
            
            
              {data.posts.slice(0, visible).map((post, index) => (
              <Card data={post} user_id={props.user_id}/>
              ))}
            {visible < data.posts.length && <div className="flex justify-center">
              <button onClick={onLoadMoreClick} type="button" className="px-6 py-3 text-sm rounded-md hover:underline bg-blue-900 text-white">Load more posts...</button>
            </div>}
            {data.posts.length > 0 && visible > data.posts.length && 
            <div className="flex justify-center text-blue-900">
              <p><em>No more posts</em></p>
            </div>}
          </div>)}
        </section>
        </div>
    </>
  )
}